import axios from 'axios'
import {Message} from 'element-ui';
import {Loading} from 'element-ui'
import common from "./common";

const request = axios.create({
    baseURL: common.requestUrl,
    timeout: 20000
})
let needLoadingRequestCount = 0
let loading

function startLoading() {
    loading = Loading.service({
        lock: true,
        text: '数据加载中',
        background: 'rgba(0,0,0,0.21)'
    })
}

function endLoading() {
    // 延迟500ms，防止网速特快加载中画面一闪而过
    setTimeout(function () {
        if (loading) loading.close()
    }, 500)
}

// 打开loading
function showFullScreenLoading() {
    if (needLoadingRequestCount === 0) {
        startLoading()
    }
    needLoadingRequestCount++
}

// 关闭loading
function tryHideFullScreenLoading() {
    if (needLoadingRequestCount <= 0) return
    needLoadingRequestCount--
    if (needLoadingRequestCount === 0) {
        endLoading()
    }
}


// 请求拦截器
request.interceptors.request.use(
    config => {
        showFullScreenLoading()
        if (sessionStorage.getItem('token')) {
            config.headers.token = sessionStorage.getItem('token')
        }
        return config
    },

    error => {
        tryHideFullScreenLoading()
        return Promise.reject(error)
    }
)

//响应拦截器
request.interceptors.response.use(success => {
    if (success.data.code && success.data.code === 500) {
        Message.error("请重新登录");
        sessionStorage.clear();
        this.$router.replace('/');
    }
    if (success.data.code && success.data.code !== 200 && success.data.code !== 500) {
        Message.warning(success.data.message);
    }

    tryHideFullScreenLoading()
    return success;

}, error => {
    if (error.response.status === 504 || error.response.status === 404) {
        Message.error({message: '服务器被吃了o(╯□╰)o'});
    } else if (error.response.status === 403) {
        Message.error({message: '权限不足,请联系管理员!'})
    } else if (error.response.status === 500) {
        Message.error({message: '尚未登录或登录过期,请登录!'});
        sessionStorage.clear();
        this.$router.replace('/');
    } else {
        if (error.response.data.message) {
            Message.error({message: error.response.data.message});
        } else {
            Message.error({message: '未知错误!'})
        }
    }
    tryHideFullScreenLoading()
    return false;
});

export default request
