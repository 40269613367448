// 导入 request
import request from '../utils/request'

// 创建比赛
export const createEvent = data => {
    return request({
        method: 'POST',
        url: '/event/addEvent',
        data
    })
}
//比赛列表
export const eventList = data => {
    return request({
        method: 'POST',
        url: '/event/findPage',
        data
    })
}
// 删除比赛
export const deleteEvent = id => {
    return request({
        method: 'GET',
        url: '/event/deleteById?id=' + id
    })
}
//关闭开启比赛
export const changeEvent = id => {
    return request({
        method: 'GET',
        url: '/event/changeEventStatus?id=' + id
    })
}

//查看链接
export const findLink = id => {
    return request({
        method: 'GET',
        url: '/event/findLink?id=' + id
    })
}

//查看详情
export const findById= id =>{
    return request({
        method: 'GET',
        url: '/event/findById?id=' + id
    })
}

//改变赛前展示
export const changeShowStatus = data => {
    return request({
        method: 'POST',
        url: '/event/changeShowStatus',
        data
    })
}

//改变赛事状态
export const editEventStatus = data => {
    return request({
        method: 'POST',
        url: '/event/editEventStatus',
        data
    })
}

//赛事抽签
export const startDraw= id =>{
    return request({
        method: 'GET',
        url: '/event/startDraw?id=' + id
    })
}

//查询开始后的比赛信息
export const startEventInfo= id =>{
    return request({
        method: 'GET',
        url: '/event/startEventInfo?id=' + id
    })
}

//比赛开始大屏切换控制
export const changeStartShow= data =>{
    return request({
        method: 'POST',
        url: '/event/changeStartShow',
        data
    })
}

//下一个选手
export const lastPersonnel= id =>{
    return request({
        method: 'GET',
        url: '/event/lastPersonnel?eventId=' + id
    })
}

//选手弃权
export const abstention= id =>{
    return request({
        method: 'GET',
        url: '/event/abstention?personnelId=' + id
    })
}

//判断评委打分是否完毕和选手是否全部比赛结束
export const validEventOver= id =>{
    return request({
        method: 'GET',
        url: '/event/validEventOver?eventId=' + id
    })
}

//评分评委获取当前选手评分信息
export const getScoreInfo= data =>{
    return request({
        method: 'POST',
        url: '/event/getScoreInfo',
        data
    })
}

//评委评分
export const judgeScore= data =>{
    return request({
        method: 'POST',
        url: '/event/judgeScore',
        data
    })
}

//主评委查询当前选手打分信息
export const getCurrentPlayerScoreInfo= id =>{
    return request({
        method: 'GET',
        url: '/event/getCurrentPlayerScoreInfo?eventId=' + id
    })
}

//重新打分
export const resetScore= id =>{
    return request({
        method: 'GET',
        url: '/event/resetScore?id=' + id
    })
}

//改变比赛后的大屏控制
export const changeEndEventStatus= data =>{
    return request({
        method: 'POST',
        url: '/event/changeEndEventStatus',
        data
    })
}

//查询比赛结束选手排名信息
export const getRanking= id =>{
    return request({
        method: 'GET',
        url: '/event/getRanking?eventId=' + id
    })
}

//参数选手顺序,分页
export const getPlayerList= data =>{
    return request({
        method: 'POST',
        url: '/event/getPlayerList',
        data
    })
}

//参数选手顺序,不分页
export const getPlayerList2= id =>{
    return request({
        method: 'POST',
        url: '/event/getPlayerList2?eventId='+id
    })
}

//查询当前选手信息
export const getCurrentPlayerInfo= id =>{
    return request({
        method: 'POST',
        url: '/event/getCurrentPlayerInfo?eventId='+id
    })
}

//查询当前选手介绍
export const getPlayerInfo= id =>{
    return request({
        method: 'POST',
        url: '/event/getPlayerInfo?eventId='+id
    })
}

//发布比赛
export const publishEvent= id =>{
    return request({
        method: 'GET',
        url: '/event/publishEvent?eventId=' + id
    })
}

//储存比赛
export const saveEventToYunSai= (data) =>{
    return request({
        method: 'POST',
        url: '/event/saveEventToYunSai',
        data
    })
}

//撤回发布
export const delPublishEvent= id =>{
    return request({
        method: 'GET',
        url: '/event/delPublishEvent?eventId=' + id
    })
}

//删除发布内容
export const delEventToYunSai= code =>{
    return request({
        method: 'GET',
        url: '/event/delEventToYunSai?code=' + code
    })
}

//获取比赛参数
export const getEventInfo= code =>{
    return request({
        method: 'GET',
        url: '/event/getEventInfo?code=' + code
    })
}


