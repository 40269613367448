import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'adminLogin',
      component: () => import('../views/adminLogin'),
    },
    {
      path: '/judgesLogin',
      name: 'judgesLogin',
      component: () => import('../views/judgesLogin'),
    },
    {
      path: '/hostLogin',
      name: 'hostLogin',
      component: () => import('../views/hostLogin'),
    },
    {
      path: '/showLogin',
      name: 'showLogin',
      component: () => import('../views/showLogin'),
    },
    {
      path: '/home',
      name: 'home',
      redirect: '/eventList',
      component: () => import('../views/Home'),
      children: [
        {path: '/eventList',  name: 'eventList', component: () => import("../components/adminModules/eventList")},
        {path: '/createEvent',  name: 'createEvent', component: () => import("../components/adminModules/createEvent")},
        {path: '/eventDetail',  name: 'eventDetail', component: () => import("../components/adminModules/eventDetail")},
        {path: '/eventEdit',  name: 'eventEdit', component: () => import("../components/adminModules/eventEdit")},
      ]
    },
    {
      path:'/index',
      name: 'index',
      component: () => import('../views/index'),
      children: [
        {path: '/score',  name: 'score', component: () => import("../components/judgesModules/score")},
        {path: '/chiefJudge',  name: 'chiefJudge', component: () => import("../components/judgesModules/chiefJudge")},
        {path: '/hostPage',  name: 'hostPage', component: () => import("../components/hostModules/hostPage")},
        {path: '/drawControl',  name: 'drawControl', component: () => import("../components/hostModules/drawControl")},
        {path: '/eventControl',  name: 'eventControl', component: () => import("../components/hostModules/eventControl")},
        {path: '/eventOver',  name: 'eventOver', component: () => import("../components/hostModules/eventOver")},
        {path: '/beforeShow',  name: 'beforeShow', component: () => import("../components/showModules/beforeShow")},
        {path: '/drawSort',  name: 'drawSort', component: () => import("../components/showModules/drawSort")},
        {path: '/eventShow',  name: 'eventShow', component: () => import("../components/showModules/eventShow")},
        {path: '/showOver',  name: 'showOver', component: () => import("../components/showModules/showOver")},
      ]
    }
  ]

})
// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
// router.beforeEach((to, from, next) => {
//   if (to.path === '/' || to.path === '/judgesLogin' || to.path === '/index') {
//     next()
//   } else {
//     const token = window.sessionStorage.getItem('token')
//     if (!token) {
//       next('/')
//     } else {
//       next()
//     }
//   }
// })

export default router
