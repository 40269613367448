import Vue from 'vue'
import Vuex from 'vuex'
import global from '../utils/common.js'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        socketTask: null,
        websocketData: {}, // 存放从后端接收到的websocket数据
        user: "",
        heartbeatInterval: null,
        timeOut: 5000  //5秒心跳发送
    },
    mutations: {
        setWebsocketData(state, data) {
            // let message = JSON.parse(data.data);
            state.websocketData = data.data
        },
        setUser(state, user) {
            state.user = user
        },
    },
    actions: {
        websocketInit({
                          state,
                          dispatch
                      }, userId) {
            let url = global.ws + userId
            // state.socketTast = uni.connectSocket({
            // 	url, // url是websocket连接ip
            // 	success: () => {
            // 		console.log('websocket连接中...')
            // 	},
            // 	fail: e => {
            // 		console.log('连接失败' + e)
            // 	}
            // })
            state.socketTast = new WebSocket(url);
            state.socketTast.onopen(() => dispatch('websocketOnOpen'))
            state.socketTast.onmessage(res => dispatch('websocketOnMessage', res))
            state.socketTast.onclose(e => dispatch('websocketOnClose'))
            state.socketTast.onerror(e => dispatch('websocketOnError'))

            //连接发生错误的回调方法
            state.socketTast.onerror = function () {
                console.log("WebSocket连接发生错误");
            };
//连接成功建立的回调方法
            state.socketTast.onopen = function () {
                console.log("WebSocket连接成功");
            }
//接收到消息的回调方法
            state.socketTast.onmessage = function (event) {
                console.log(event.data);
            }
//连接关闭的回调方法
            state.socketTast.onclose = function () {
                console.log("WebSocket连接关闭");
            }
        },
        websocketOnOpen({
                            state,
                            commit,
                            dispatch
                        }) {
            dispatch('startHeart')
            console.log('WebSocket连接成功！')
        },
        // 收到数据
        websocketOnMessage({
                               commit
                           }, res) {
            commit('setWebsocketData', res)
        },
        websocketOnClose({
                             commit,
                             state,
                             dispatch
                         }) {
            state.socketTast = null
            console.log('WebSocket连接关闭')
            if (state.user) {
                dispatch('websocketInit', state.user)
            }
            if (state.heartbeatInterval) {
                clearInterval(state.heartbeatInterval)
            }
        },
        websocketOnError({
                             commit,
                             dispatch
                         }) {
            console.log('WebSocket连接错误')
        },
        websocketClose({
                           state,
                           dispatch
                       }) {
            if (!state.socketTast) {
                return false
            }
            state.socketTast.close({
                success(res) {
                    console.log('关闭成功', res)
                    if (state.heartbeatInterval) {
                        clearInterval(state.heartbeatInterval)
                    }
                },
                fail(err) {
                    console.log('关闭失败', err)
                }
            })
        },
        //检测心跳
        startHeart({state, dispatch}) {
            if (state.heartbeatInterval) {
                clearInterval(state.heartbeatInterval)
            }
            state.heartbeatInterval = setInterval(() => {
                dispatch('websocketSend', 'ping')
            }, state.timeOut)
        },
        // 发送数据
        websocketSend({
                          state,
                          dispatch
                      }, data) {
            if (!state.socketTast) {
                console.log('发送失败,未连接')
                return false
            }
            uni.sendSocketMessage({
                data,
                success: res => {
                    // console.log('发送成功', res)
                },
                fail: e => {
                    console.log('发送失败', e)
                }
            })
        },


    }

})

export default store