<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="less">
:root {
  /* 背景色 */
  --theme_font-size: 3vw;
  --theme_font-color: #fff;
  --theme_bg-color: #bf353b;
  --theme_bg-img: url("./assets/images/showBanner.png");
}

#app {
  width: 100%;
  height: 100vh;
}


</style>

<script>
import {getEventInfo} from './api/event'

export default {
  data() {
    return {}
  },
  methods: {
    getEventInfo() {
      // let eventInfo = sessionStorage.getItem('event')
      var url = window.location.href;             //获取当前url
      var dz_url = url.split('#')[0];                //获取#/之前的字符串
      var cs = dz_url.split('?')[1];                //获取?之后的参数字符串
      var vl = dz_url.split('=')[1];
      if (vl){
        getEventInfo(vl).then(res => {
          if (res.data.code === 200) {
            //字体大小
            document.documentElement.style.setProperty("--theme_font-size", res.data.data.fontSize + 'vw');
            //字体颜色
            document.documentElement.style.setProperty("--theme_font-color", res.data.data.fontColor);
            //背景颜色
            document.documentElement.style.setProperty("--theme_bg-color", res.data.data.bgColor);
            //背景图地址
            document.documentElement.style.setProperty("--theme_bg-img", `url(${res.data.data.bgImg})`);
            sessionStorage.setItem('setInfo',JSON.stringify(res.data.data))
          }
        })
      } else {
        let c = JSON.parse(sessionStorage.getItem('setInfo'))
        //字体大小
        document.documentElement.style.setProperty("--theme_font-size", c.fontSize + 'vw');
        //字体颜色
        document.documentElement.style.setProperty("--theme_font-color", c.fontColor);
        //背景颜色
        document.documentElement.style.setProperty("--theme_bg-color", c.bgColor);
        //背景图地址
        document.documentElement.style.setProperty("--theme_bg-img", `url(${c.bgImg})`);
      }


    }
  },
  mounted() {
    this.getEventInfo()
  }
}
</script>
