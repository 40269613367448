//本地接口
// const ws = 'ws://192.168.0.129:9091/connectWebSocket/';
// const requestUrl = 'http://192.168.0.129:9091'

const ws = 'wss://score.yunsaigansu.top/websocket/connectWebSocket/';
const requestUrl = '/api'

export default {
    requestUrl,
    ws
}
